import { Button, Typography, Image } from "@/ui";
import { IconAIStar, IconAIStars } from "@/icons";

import useStyles from "./styles";
import { staticUrl } from "@/lib/utils";
import { useMediaQuery, useTheme } from "@material-ui/core";

const BannerAiPromo = ({ isHome }) => {
  const classes = useStyles();
  const theme = useTheme();

  const isSmall = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <aside className={`${classes.root} ${isHome ? classes.rootHome : ""}`}>
      <div className={classes.left}>
        {!isSmall && <IconAIStars size="lg" viewBox="0 0 50 50" />}

        <div>
          {!isSmall && (
            <Typography component="p" variant="h1" className={classes.heading}>
              Your AI Travel Advisor
            </Typography>
          )}
          {isSmall && (
            <Typography component="p" variant="h1" className={classes.heading}>
              <span>Your AI Travel</span>{" "}
              <span className={classes.smallIcon}>
                <span>Advisor</span>{" "}
                <IconAIStars size="md" viewBox="0 0 50 50" />
              </span>
            </Typography>
          )}
          <Typography
            className={classes.message}
            variant="caption"
          >{`Build your trip for free with AI. Then book it with a travel advisor to make it real.`}</Typography>
        </div>
      </div>
      <div className={`${classes.right} ${isHome ? classes.rightHome : ""}`}>
        <div className={classes.avatarContainer}>
          <Image
            src={staticUrl(require("@/static/img/how-it-works-avatar-01.jpg"))}
            className={classes.userAvatarImg}
            height={32}
            width={32}
          />
          <Image
            src={staticUrl(require("@/static/img/how-it-works-avatar-02.jpg"))}
            className={classes.userAvatarImg}
            height={32}
            width={32}
          />
          <Image
            src={staticUrl(require("@/static/img/how-it-works-avatar-03.jpg"))}
            className={classes.userAvatarImg}
            height={32}
            width={32}
          />
          <Image
            src={staticUrl(require("@/static/img/how-it-works-avatar-04.jpg"))}
            className={classes.userAvatarImg}
            height={32}
            width={32}
          />
          <Image
            src={staticUrl(require("@/static/img/how-it-works-avatar-05.jpg"))}
            className={classes.userAvatarImg}
            height={32}
            width={32}
          />
        </div>

        <Button
          href="/ai-itinerary-planner/"
          className={classes.button}
          variant="contained"
          classes={{
            label: classes.buttonLabel,
          }}
        >
          <IconAIStar size="sm" viewBox="0 0 45 45" />
          Try It Now
        </Button>
      </div>
    </aside>
  );
};

export default BannerAiPromo;
