import { staticUrl } from "@/lib/utils";
import { makeStyles } from "@material-ui/core";

const bgLarge = staticUrl(require("@/static/img/hero-large.jpg"));
const bgMedium = staticUrl(require("@/static/img/hero-medium.jpg"));
const bgSmall = staticUrl(require("@/static/img/hero-small.jpg"));

const styles = (theme) => ({
  root: {
    backgroundImage: `url(${bgLarge})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    padding: theme.spacing(4),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "stretch",

    [theme.breakpoints.down("sm")]: {
      margin: `0 -${theme.spacing(3)}px`,
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },

  rootHome: {
    backgroundImage: `url(${bgMedium})`,
    minHeight: "400px",
    flexDirection: "column",
    maxWidth: "1192px",
    flexGrow: 1,

    [theme.breakpoints.down("md")]: {
      minHeight: "340px",
    },

    [theme.breakpoints.down("sm")]: {
      backgroundImage: `url(${bgLarge})`,
    },

    [`@media (max-width:${theme.breakpoints.values.xsm}px)`]: {
      backgroundImage: `url(${bgSmall})`,
    },
  },

  message: {
    fontSize: theme.atlasGuru.fontSizes.h3.xs,
    lineHeight: 1.2,
    marginTop: theme.spacing(),

    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(3),
      maxWidth: "80%",
    },
  },

  left: {
    maxWidth: "400px",
    color: theme.palette.primary.main,
    display: "flex",
    alignItems: "start",

    [theme.breakpoints.up("sm")]: {
      "& .icon": { marginTop: theme.spacing() },
    },
  },

  right: {
    display: "flex",
    flexDirection: "column",
    alignSelf: "center",
    alignItems: "center",
    whiteSpace: "nowrap",

    [theme.breakpoints.down("sm")]: {
      alignSelf: "start",
    },
  },

  rightHome: {
    alignSelf: "start",
    marginLeft: theme.spacing(6),

    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
    },
  },

  avatarContainer: {
    marginBottom: theme.spacing(),
    marginLeft: `-${theme.spacing(0.75)}px`,
  },

  userAvatarImg: {
    borderRadius: "50%",
    border: `1px solid ${theme.palette.common.white}`,
    margin: `0 -4px`,

    "&:first-of-type": {
      marginLeft: 0,
    },
  },

  heading: {
    color: theme.palette.primary.main,
  },

  smallIcon: { display: "flex", gap: theme.spacing() },

  button: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
    borderRadius: theme.spacing(3),
    paddingTop: 0,
    paddingBottom: 0,

    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.white,

      "&:hover svg": {
        color: theme.palette.common.white,
      },
    },
  },

  buttonLabel: {
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
    whiteSpace: "nowrap",
  },
});

export default makeStyles(styles);
